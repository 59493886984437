import FETCH from '../utils/security/fetch';
import * as APIString from '../constants/apiString';
import { store } from '@/store/store';
import queryString from 'query-string';
import { getDeviceResolution } from '@/shared/utils/utils';

const CONTENT_DETAIL_SELECT_FIELDS = [
  'category',
  'content_categories',
  'genre',
  'id',
  'images',
  'long_description',
  'people',
  'regions',
  'release_date',
  'short_description',
  'provider_slug',
  'restriction',
  'slug',
  'title',
  'total_episodes',
  'released_episode_count',
  'trailers',
  'seasons',
  'type',
  'video_source',
  'price',
  'metadata',
  'runtime',
];

const postFirstFiveSecPlay = contentId => {
  const { account } = store.getState().auth;
  if (!account) {
    return Promise.resolve(true);
  }
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const path = `/tenants/${APIString.TENANT_SLUG}/contents/${contentId}/view`;
  return FETCH(path, 'POST', {
    Authorization,
  });
};

const getLiveInfo = chanelId => {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const paramsQuery = queryString.stringify({ timezone });
  const path = `/tenants/${APIString.TENANT_SLUG}/channels/${chanelId}/live_info/?${paramsQuery}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
};

const getRecommend = contentId => {
  const { account } = store.getState().auth;

  let Authorization = '';
  const queryParams = queryString.stringify(getDeviceResolution());
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const path = `/tenants/${APIString.TENANT_SLUG}/contents/${contentId}/recommended?${queryParams}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
};

const getScrubberThumbnail = contentId => {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const path = `/tenants/${APIString.TENANT_SLUG}/contents/${contentId}/scrubber_template`;
  return FETCH(path, 'GET', {
    Authorization,
  });
};

const getRelatedRibbons = contentId => {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const path = `/tenants/${APIString.TENANT_SLUG}/contents/${contentId}/related_ribbons`;
  return FETCH(path, 'GET', {
    Authorization,
  });
};

const getGapContents = (chanelId, gapDuration) => {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const path = `/tenants/${APIString.TENANT_SLUG}/channels/${chanelId}/search_gap_content/?gap_duration=${gapDuration}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
};

const getProgramShow = idContent => {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const select = JSON.stringify({
    Content: CONTENT_DETAIL_SELECT_FIELDS,
  });
  let data = { select, ...getDeviceResolution() };
  const queryParams = queryString.stringify(data);
  const path = `/tenants/${APIString.TENANT_SLUG}/contents/${idContent}/program?${queryParams}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
};

const getConfigCalenderEpg = idContent => {
  const { account } = store.getState().auth;
  let Authorization = '';
  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const path = `/tenants/${APIString.TENANT_SLUG}/tenant_platforms/${APIString.ROOT_PLATFORM_MENU_ID}/configs/`;
  return FETCH(path, 'GET', {
    Authorization,
  });
};

export {
  postFirstFiveSecPlay,
  getLiveInfo,
  getRecommend,
  getScrubberThumbnail,
  getRelatedRibbons,
  getGapContents,
  getProgramShow,
  getConfigCalenderEpg,
};
