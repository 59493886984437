import * as types from './actionTypes';

export function getMenu(menu) {
  return {
    type: types.GET_MENU,
    menu,
  };
}

export function getPage(page) {
  return {
    type: types.GET_PAGE,
    page,
  };
}

export function getDetail(detail) {
  return {
    type: types.GET_DETAIL,
    detail,
  };
}

export function refreshPage(isRefreshPage) {
  return {
    type: types.REFRESH_PAGE,
    isRefreshPage,
  };
}

export function updateDetailPage(payload) {
  return {
    type: types.UPDATE_DETAIL_PAGE,
    payload: payload,
  };
}

export function updateDisclaimer(value) {
  return {
    type: types.UPDATE_DISCLAIMER,
    value,
  };
}

export function updateEpgList(payload) {
  return {
    type: types.UPDATE_EPGLIST,
    payload,
  };
}

export function setVolume(payload) {
  return {
    type: types.SET_VOLUME,
    payload,
  };
}

export function setCurrentLink(payload) {
  return {
    type: types.SET_CURRENT_LINK,
    payload,
  };
}

export function updateCuratedLive(payload) {
  return {
    type: types.UPDATE_CURATED_LIVE,
    payload,
  };
}

export function setTokenDrm(value) {
  return {
    type: types.UPDATE_TOKEN_DRM,
    value,
  };
}
