import * as types from './actionTypes';

export function getMenu(menu) {
  return {
    type: types.GET_MENU,
    menu,
  };
}

export function getPage(page) {
  return {
    type: types.GET_PAGE,
    page,
  };
}

export function getDetail(detail) {
  return {
    type: types.GET_DETAIL,
    detail,
  };
}

export function refreshPage(isRefreshPage) {
  return {
    type: types.REFRESH_PAGE,
    isRefreshPage,
  };
}
