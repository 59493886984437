import React from 'react';
import { styled } from 'twin.macro';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAdsBanner } from '@/api/appAPIs';
import { REACT_APP_ROOT_PLATFORM_MENU_ID } from '../constants';
import { DFPSlotsProvider, AdSlot, DFPManager } from 'react-dfp';
import uuid from 'react-uuid';
import { isDesktop } from 'react-device-detect';
import jquery from 'jquery';

class AdsBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShow: true,
      adsBanner: null,
      adsBannerType: 'SCRIPT',
      iframeLoaded: false,
      interval: null,
      adsVerical: false,
      displayError: false,
      timeRefreshAds: 45,
    };
    this.SCRIPT = 'SCRIPT';
    this.GOOGLE_DFP = 'GOOGLE_DFP';
    this._id = uuid();
    this._count = 0;
    this.timeOut = null;
    this.interval = null;
  }

  componentWillMount() {
    const { auth, position } = this.props;
    if (
      auth &&
      auth.account &&
      auth.account.profile &&
      auth.account.profile.subscription_plan_info
    ) {
      this.setState({
        isShow: false,
      });
    }
    if (position === 'left' || position === 'right') {
      this.setState({
        adsVerical: true,
      });
    }
  }

  componentDidMount() {
    const { isShow, timeRefreshAds } = this.state;
    const that = this;
    if (!isShow) {
      return;
    }
    this._refreshAds(0)
      .then(res => {
        this._refreshAds(timeRefreshAds);
        jquery(window).on('resize', () => {
          that._autoScaleBanner();
        });
      })
      .catch();
    DFPManager.configureLazyLoad(true, {
      fetchMarginPercent: 500,
      renderMarginPercent: 200,
      mobileScaling: 2.0,
    });
  }

  componentWillUnmount() {
    clearInterval(this.timeOut);
    clearInterval(this.interval);
  }

  _refreshAds = timer => {
    const platformSlug = REACT_APP_ROOT_PLATFORM_MENU_ID;
    let data = {};
    if (this.props.position) {
      data = {
        position: this.props.position.toUpperCase(),
      };
    }
    timer = timer * 1000;
    return new Promise((resolve, reject) => {
      this.timeOut = setInterval(() => {
        getAdsBanner(platformSlug, data)
          .then(res => {
            if (jquery.isEmptyObject(res)) {
              return;
            }
            let dataAdsBanner = null;
            if (Array.isArray(res)) {
              res.every((ads, index) => {
                if (dataAdsBanner) {
                  return false;
                }
                if (ads.position.toUpperCase() === this.props.position.toUpperCase()) {
                  dataAdsBanner = ads;
                }
              });
            }
            this._getAdsBanner(dataAdsBanner);
            this._autoScaleBanner();
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
        if (timer === 0) {
          clearInterval(this.timeOut);
        }
      }, timer);
    });
  };

  _autoScaleBanner = () => {
    const { position, rezise } = this.props;
    if (position === 'medium_rectangle' && rezise) {
      return;
    }
    if (this.state.interval) {
      clearInterval(this.state.interval);
    }
    const iframeInterval = setInterval(() => {
      const containerAdsBanner = jquery(`#${this._id}`);
      const iframes = jquery(`#${this._id} .adBox iframe`);
      this._count++;
      if (this._count > 50) {
        clearInterval(this.state.interval);
        return;
      }
      if (!iframes.length) {
        return;
      }

      iframes.map((index, iframe) => {
        const $iframe = jquery(iframe);
        const w = $iframe.attr('width');
        const h = $iframe.attr('height');

        const ctnW = containerAdsBanner.innerWidth();
        const ctnH = containerAdsBanner.innerHeight();

        let ratio = 1;
        let percent = 1;
        if (w > ctnW) {
          ratio = ctnW / w;
          percent = ratio;
        }
        const newW = w * ratio;
        const newH = h * ratio;

        if (newH > 0) {
          const ads = containerAdsBanner.find('> div').css({
            transform: `scale(${percent})`,
          });
          containerAdsBanner.height(newH);
          containerAdsBanner.width(newW);

          // $iframe.width(newW).height(newH)
          // const imageAd = $iframe.contents().find('img, .img_ad')
          // .css({
          //   'max-width' : '100%',
          //   height: newH,
          //   width: newW
          // })
        }
      });
      clearInterval(this.state.interval);
    }, 100);
    this.setState({
      interval: iframeInterval,
    });
  };

  render() {
    const { adsVerical, displayError, adsBanner } = this.state;
    const { position, nonMargin } = this.props;
    if (!adsBanner) {
      return null;
    }
    return (
      <DivStyled
        className={`v-ads-banner ${adsVerical ? 'v-detailPage__ads' : 'v-ads-banner__wrap'}`}
        visible={!!adsBanner || isDesktop}
        ads={{
          padding: position === 'medium_rectangle',
          margin: nonMargin,
          displayError: displayError,
        }}
      >
        {this.state.isShow && (
          <div id={this._id} className="v-ads-banner--ads">
            {this._renderAdsBanner()}
          </div>
        )}
      </DivStyled>
    );
  }
  _renderAdsBanner = () => {
    if (this.state.adsBannerType == this.SCRIPT) {
      jquery(`#${this._id}`).html(this.state.adsBanner);
      return null;
    }

    if (this.state.adsBannerType == this.GOOGLE_DFP) {
      return this.state.adsBanner;
    }

    return null;
  };
  _getSize = size => {
    let result = [];
    if (!size) {
      return result;
    }
    let sizeParse = size;
    if (typeof size === 'string') {
      sizeParse = JSON.parse(size);
    }
    result = sizeParse.map((object, index) => {
      return [parseInt(object.width), parseInt(object.height)];
    });
    return result;
  };

  displayError = eventData => {
    const { position } = this.props;
    if (position === 'bottom' || position === 'top') {
      return;
    }
    this.setState({
      displayError:
        eventData.adElementRef.current.clientHeight === 0 || eventData.event.isEmpty ? true : false,
    });
  };
  _getAdsBanner = res => {
    if (!res) {
      return;
    }
    this.setState(
      {
        adsBanner: null,
        adsBannerType: null,
      },
      () => {
        const { ads_banner_url, ads_provider, position, sizes } = res;
        if (!ads_provider || jquery.isEmptyObject(ads_provider)) {
          this.setState({
            adsBanner: ads_banner_url,
            adsBannerType: this.SCRIPT,
          });
          return;
        }
        let ads = null;
        const { dfp_ad_unit, dfp_network_id, type } = ads_provider;
        if (type == this.SCRIPT) {
          this.setState({
            adsBanner: ads_banner_url,
            adsBannerType: this.SCRIPT,
          });
        }
        if (type == this.GOOGLE_DFP) {
          ads = (
            <DFPSlotsProvider dfpNetworkId={dfp_network_id} collapseEmptyDivs>
              <AdSlot
                adUnit={dfp_ad_unit}
                slotId={position}
                sizes={this._getSize(sizes)}
                onSlotRender={eventData => this.displayError(eventData)}
              />
            </DFPSlotsProvider>
          );
          this.setState(
            {
              adsBanner: ads,
              adsBannerType: this.GOOGLE_DFP,
            },
            () => {
              clearInterval(this.timeOut);
              clearInterval(this.interval);
              this.interval = setInterval(() => {
                DFPManager.refresh(position);
                this._autoScaleBanner();
              }, this.state.timeRefreshAds * 1000);
            },
          );
        }
      },
    );
  };
}
const DivStyled = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  padding: ${props => (props.ads.padding ? 0 : '0 20')}px;
  justify-content: center;
  margin: ${props => (props.ads.margin ? 0 : '1rem 0')};
  height: 100%;
  position: relative;
  min-height: 1.5rem;
  &:before {
    content: '\\f127';
    background: #dadada;
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 90%;
    font-family: FontAwesome;
    display: ${props => `${props.ads.displayError ? `flex` : `none`}`};
    justify-content: center;
    align-items: center;
    color: #8a8a8a;
    font-size: 1rem;
    opacity: 0.95;
  }
  .v-ads-banner {
    &--ads {
      z-index: 2;
      flex-basis: 100%;
      display: flex;
      justify-content: center;
      overflow: hidden;
      > div:first-of-type {
        transform-origin: top;
      }
    }
  }
  @media (min-width: 768px) {
    padding: ${props => (props.ads.padding ? 0 : '0 35')}px;
  }
  @media (min-width: 992px) {
  }

  @media (min-width: 1280px) {
  }
`;

const mapStateToProps = state => ({
  auth: state.auth,
});

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdsBanner));
