export const MOVIE_TYPE = 1;
export const SHOW_TYPE = 2;
export const SEASON_TYPE = 3;
export const EPISODE_TYPE = 4;
export const CHANNEL_TYPE = 5;
export const CLIP = 6;
export const CURATED_LIVE = 7;

export const BANNER = 0;
export const THUMBNAIL = 1;
export const POSTER = 2;
export const CLASS_0 = 'banner';
export const CLASS_1 = 'thumbnail';
export const CLASS_2 = 'poster';

export const YOUTUBE_UNSTARTED = -1;
export const YOUTUBE_ENDED = 0;
export const YOUTUBE_PLAYING = 1;
export const YOUTUBE_PAUSED = 2;
export const YOUTUBE_BUFFERING = 3;
export const YOUTUBE_CUED = 5;

export const AGE_RESTRICTIONS_18 = 1;
