import * as types from './actionTypes';

export function getMenu(menu) {
  return {
    type: types.GET_MENU,
    menu,
  };
}

export function getPage(page) {
  return {
    type: types.GET_PAGE,
    page,
  };
}

export function getDetail(detail) {
  return {
    type: types.GET_DETAIL,
    detail,
  };
}

export function refreshPage(isRefreshPage) {
  return {
    type: types.REFRESH_PAGE,
    isRefreshPage,
  };
}

export function updateLandingPage(payload) {
  return {
    type: types.UPDATE_LANDING_PAGE,
    payload: payload,
  };
}

export function reinitLandingPage(payload) {
  return {
    type: types.REINIT_LANDING_PAGE,
    payload: payload,
  };
}

export function updateDetailLandingPage(payload) {
  return {
    type: types.UPDATE_DETAIL_LANDING_PAGE,
    payload: payload,
  };
}

export function updateSeasonLandingPage(payload) {
  return {
    type: types.UPDATE_SEASON_LANDING_PAGE,
    payload: payload,
  };
}

export function setProgressCache(payload) {
  return {
    type: types.SET_PROGRESS_CACHE,
    payload: payload,
  };
}
