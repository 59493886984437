import FETCH from '../utils/security/fetch';
import * as APIString from '../constants/apiString';
import { store } from '@/store/store';
import queryString from 'query-string';
import { getDeviceResolution } from '@/shared/utils/utils';
import { CONTENT_DETAIL_SELECT_FIELDS, viewDetail } from './contentApi';

export { getDetail, viewDetail, getSeason };

export const CONTENT_DETAIL_SEASON_SELECT_FIELDS = ['id', 'slug', 'title', 'min_sub_tier'];

export const CONTENT_DETAIL_TRAILER_SELECT_FIELDS = [
  'id',
  'images',
  'is_new_release',
  'title',
  'min_sub_tier',
  'slug',
];

export const CONTENT_SEASON_SELECT_FIELDS = [
  'episodes',
  'id',
  'slug',
  'title',
  'is_new_release',
  'min_sub_tier',
];

export const CONTENT_SEASON_ITEMS_SELECT_FIELDS = [
  'id',
  'slug',
  'title',
  'video_source',
  'is_premium',
  'is_new_release',
  'images',
  'can_preview',
  'episode',
  'type',
  'is_watchable',
  'min_sub_tier',
  'show_info',
];

function getDetail(id) {
  const { account } = store.getState().auth;
  let Authorization = '';
  const select = JSON.stringify({
    Content: CONTENT_DETAIL_SELECT_FIELDS,
    SeasonList: CONTENT_DETAIL_SEASON_SELECT_FIELDS,
    TrailerSerializerDocumentation: CONTENT_DETAIL_TRAILER_SELECT_FIELDS,
  });
  let data = { select, ...getDeviceResolution() };

  const queryParams = queryString.stringify(data);

  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const path = `/tenants/${APIString.TENANT_SLUG}/contents/${id}/detail?${queryParams}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}

function getSeason(id) {
  const { account } = store.getState().auth;
  let Authorization = '';
  const select = JSON.stringify({
    SeasonDetail: CONTENT_SEASON_SELECT_FIELDS,
    Content: CONTENT_SEASON_ITEMS_SELECT_FIELDS,
  });
  let data = { select, ...getDeviceResolution() };
  const queryParams = queryString.stringify(data);

  if (account) {
    Authorization = account.access_token ? account.access_token : '';
  }
  const path = `/tenants/${APIString.TENANT_SLUG}/seasons/${id}/?${queryParams}`;
  return FETCH(path, 'GET', {
    Authorization,
  });
}
