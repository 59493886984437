import * as actions from './actions';
import * as appAPIs from '@/api/appAPIs';
import * as homePageApi from '@/api/homePageApi';
import queryString from 'query-string';

export {
  getMenu,
  getPage,
  getRibbon,
  getPageMenu,
  getDetail,
  getSeason,
  refreshPage,
  getRelate,
  getEpg,
  getRibbonDetail,
};

function getMenu() {
  return dispatch => {
    appAPIs
      .get_menu()
      .then(response => {
        dispatch(actions.getMenu(response));
      })
      .catch(err => {
        console.log('response', err);
      });
  };
}

function getPage(uuid, slug = '') {
  return dispatch => {
    appAPIs
      .get_page(uuid, slug)
      .then(response => {
        dispatch(actions.getPage(response));
      })
      .catch(err => {
        console.log('response', err);
      });
  };
}

function getPageMenu(id, uuid) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_page_menu(id, uuid)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getRibbon(id, page) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_ribbon(id, page)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getDetail(slug) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_detail(slug)
        .then(response => {
          dispatch(actions.getDetail(response));
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getSeason(seasonId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_season(seasonId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function refreshPage(isRefreshPage) {
  return dispatch => {
    dispatch(actions.refreshPage(isRefreshPage));
  };
}

function getRelate(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_relate(id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getEpg(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_epg(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getRibbonDetail(pageSlug, ribbonSlug, search) {
  const queryParams = queryString.parse(search);
  return dispatch => {
    return new Promise((resolve, reject) => {
      if (![5, 9].includes(parseInt(queryParams?.type, 10))) {
        homePageApi
          .getRibbonDetail(pageSlug, ribbonSlug, search)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      } else {
        homePageApi
          .getRibbonDetailRecent(pageSlug, ribbonSlug, search)
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      }
    });
  };
}
