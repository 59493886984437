import FETCH from '../utils/security/fetch';
import * as APIString from '../constants/apiString';

export { getFaqs, getIdFaqBlockAds };

function getFaqs() {
  const path = `/tenants/${APIString.TENANT_SLUG}/tenant_platforms/${APIString.ROOT_PLATFORM_MENU_ID}/faqs/`;
  return FETCH(path, 'GET', {});
}

function getIdFaqBlockAds() {
  const path = `/tenants/${APIString.TENANT_SLUG}/tenant_platforms/${APIString.ROOT_PLATFORM_MENU_ID}/qnas/?ads_block=true`;
  return FETCH(path, 'GET', {});
}
