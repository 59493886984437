import * as actions from './actions';
import * as appAPIs from '@/api/appAPIs';
import * as apiDetail from '@/api/detailPage';
import { MOVIE_TYPE, EPISODE_TYPE } from './constants';
import * as epgApi from '@/api/epg';
import * as landdingPageApi from '@/api/landdingPageApi';
import * as configApi from '@/api/config';
import * as faqApi from '@/api/faq';
import { setProgressCache } from '@/shared/features/landingpage/actions';

export {
  getPage,
  getRibbon,
  getPageMenu,
  getDetail,
  getSeason,
  refreshPage,
  getRelate,
  getEpg,
  postProgress,
  getAdsVideo,
  updateDisclaimer,
  getEpglist,
  getDetails,
  getReportTemplates,
  postReport,
  uploadImage,
  setVolume,
  setCurrentLink,
  postFirstFiveSecPlay,
  getMuxEnvKey,
  getLiveInfo,
  getRecommend,
  getInfoDetail,
  getScrubberThumbnail,
  getRelatedRibbons,
  getGapContents,
  getIdFaqBlockAds,
  getProgramShow,
  getConfigCalenderEpg,
};

function getPage(uuid, isRefresh = false) {
  return dispatch => {
    if (isRefresh) {
      dispatch(actions.refreshPage(true));
    }

    appAPIs
      .get_page(uuid)
      .then(response => {
        dispatch(actions.getPage(response));

        if (isRefresh) {
          setTimeout(() => {
            dispatch(actions.refreshPage(false));
          }, 1000);
        }
      })
      .catch(err => {
        console.log('response', err);
      });
  };
}

function getPageMenu(id, uuid) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_page_menu(id, uuid)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getRibbon(id, page) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_ribbon(id, page)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getDetail(slug) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_detail(slug)
        .then(response => {
          dispatch(actions.getDetail(response));
          if (response && (response.type === MOVIE_TYPE || response.type === EPISODE_TYPE)) {
            dispatch(actions.updateDetailPage(response));
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getDetails(slug, isDispatch = true) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const promises = [
        new Promise((resolve, reject) => {
          landdingPageApi
            .viewDetail(slug)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        }),
        new Promise((resolve, reject) => {
          landdingPageApi
            .getDetail(slug)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        }),
      ];
      return Promise.all(promises)
        .then(data => {
          let result = {};
          data.map(value => {
            Object.assign(result, value);
          });

          if (isDispatch) {
            dispatch(actions.getDetail(result));
          }
          resolve(result);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getSeason(seasonId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_season(seasonId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function refreshPage(isRefreshPage) {
  return dispatch => {
    dispatch(actions.refreshPage(isRefreshPage));
  };
}

function getRelate(id) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_relate(id)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getEpg(params) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .get_epg(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function postProgress(contentId, progress) {
  return dispatch => {
    dispatch(setProgressCache({ id: contentId, progress }));
    return new Promise((resolve, reject) => {
      appAPIs
        .post_progress(contentId, progress)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getAdsVideo(params = {}) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .getAdsVideo(params)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function updateDisclaimer(value) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.updateDisclaimer(value));
      resolve();
    });
  };
}

function getEpglist(params = {}) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      const epgApiGet = (params = {}) => {
        epgApi
          .getEpgs(params)
          .then(response => {
            resolve(response);
            dispatch(actions.updateEpgList(response.items));
          })
          .catch(err => reject(err));
      };
      // epgApi
      //   .getIPInfo()
      //   .then((response) => {
      //     params = { ...params, timeZone: response.timezone }
      //     dispatch(updateIP(response))
      //     epgApiGet(params)
      //   })
      //   .catch((err) => {
      //     epgApiGet(params)
      //   })
      epgApiGet(params);
    });
  };
}

function getReportTemplates() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .reportTemplates()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function postReport(id, data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .postReport(id, data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function uploadImage(data) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      appAPIs
        .uploadImage(data)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function setVolume(value) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.setVolume(value));
      resolve(true);
    });
  };
}

function setCurrentLink(value) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(actions.setCurrentLink(value));
      resolve(true);
    });
  };
}

function postFirstFiveSecPlay(contentId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiDetail
        .postFirstFiveSecPlay(contentId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getMuxEnvKey() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      configApi
        .getMuxEnvKey()
        .then(response => {
          resolve(response);
        })
        .catch(errors => {
          reject(errors);
        });
    });
  };
}

function getLiveInfo(idChannel) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiDetail
        .getLiveInfo(idChannel)
        .then(response => {
          dispatch(actions.updateCuratedLive(response));
          resolve(response);
        })
        .catch(errors => {
          reject(errors);
        });
    });
  };
}

function getRecommend(contentId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiDetail
        .getRecommend(contentId)
        .then(response => {
          resolve(response);
        })
        .catch(errors => {
          reject(errors);
        });
    });
  };
}

function getInfoDetail(slug) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      landdingPageApi
        .getDetail(slug)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getScrubberThumbnail(contentId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiDetail
        .getScrubberThumbnail(contentId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getRelatedRibbons(contentId) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiDetail
        .getRelatedRibbons(contentId)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getGapContents(idChannel, param) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiDetail
        .getGapContents(idChannel, param)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getIdFaqBlockAds() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      faqApi
        .getIdFaqBlockAds()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getProgramShow(idContent) {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiDetail
        .getProgramShow(idContent)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}

function getConfigCalenderEpg() {
  return dispatch => {
    return new Promise((resolve, reject) => {
      apiDetail
        .getConfigCalenderEpg()
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}
